<template>
  <ion-page>
    <ion-header mode="md" class="header ion-no-border">
      <ion-toolbar class="px-3 py-2 header-modal">
        <ion-label class="title-modal" slot="start">{{
          idModal === 'viewOrder' ? $t('order_delivery_instruction') : $t('customer_instruction')
        }}</ion-label>
        <ion-icon slot="end" :icon="closeOutline" @click="$emit('closeModal', instruction)"></ion-icon>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-item lines="none" class="py-3 input-area">
        <ion-textarea
          class="instruction"
          :class="typing ? 'input-instruction-on' : 'input-instruction-off'"
          :auto-grow="true"
          :placeholder="$t('ex_delivery_instruction')"
          :readonly="idModal === 'view' || idModal === 'viewOrder'"
          mode="md"
          @ionBlur="userTyping(false)"
          @ionFocus="userTyping(true)"
          @ionChange="checkMaxInput($event)"
          @ionInput="checkMaxInput($event)"
          v-model="deliveryInstruction"
          :maxlength="255"
          :style="
            !typing
              ? 'border: none'
              : typing && instructionLength < 255
              ? 'border: 1.2px solid #00676a'
              : 'border: 1.2px solid #F04438'
          "
        >
        </ion-textarea>
      </ion-item>
      <div class="counter px-3 mb-3">
        <ion-text :class="!typing ? 'grey' : typing && instructionLength < 255 ? 'green' : 'red'">
          {{ instructionLength }}/255
        </ion-text>
      </div>
    </ion-content>
    <ion-footer class="foot ion-no-border" mode="md">
      <ion-toolbar v-if="idModal === 'view' || idModal === 'viewOrder'" class="px-3 pb-3 foot">
        <ion-button class="close-btn" expand="block" @click="$emit('closeModal', instruction)">
          {{ $t('close') }}
        </ion-button>
      </ion-toolbar>
      <ion-toolbar v-else class="px-3 pb-3 foot">
        <ion-button
          class="confirm-btn"
          slot="start"
          color="primary"
          fill="outline"
          @click="$emit('closeModal', instruction)"
          >{{ $t('close') }}</ion-button
        >
        <ion-button
          class="confirm-btn"
          slot="end"
          :disabled="!typing || deliveryInstruction?.length > 255"
          @click="$emit('closeModal', deliveryInstruction)"
        >
          {{ $t('save') }}
          <ion-icon slot="end" :icon="checkmarkOutline"></ion-icon>
        </ion-button>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>
<script>
import { checkmarkOutline, closeOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    instruction: {
      type: String,
      default: ''
    },
    idModal: {
      type: String,
      default: ''
    }
  },
  computed: {
    haveInstruction() {
      return this.instruction === '' || this.instruction === null;
    },
    instructionLength() {
      return this.deliveryInstruction?.length;
    }
  },
  setup() {
    return {
      closeOutline,
      checkmarkOutline
    };
  },
  data() {
    return {
      typing: false,
      deliveryInstruction: this.instruction
    };
  },
  methods: {
    userTyping(params) {
      this.typing = this.idModal === 'view' || this.idModal === 'viewOrder' ? false : params;
    },
    checkMaxInput(event) {
      if (this.deliveryInstruction.length > 255) {
        this.deliveryInstruction = this.deliveryInstruction.slice(0, 255);
        event.target.value = this.deliveryInstruction;
      }
    }
  }
});
</script>
<style src="./style.scss" lang="scss" scoped></style>
